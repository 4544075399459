import { gql, useLazyQuery } from "@apollo/client";
import { Card, List } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import Text from "antd/lib/typography/Text";
import styled from "styled-components";
import { DATE_TODAY } from "../constants";

export function EncodedByDate() {
  const [isInit, setIsInit] = useState<boolean>(false);
  const [date, setDate] = useState<string>(DATE_TODAY);

  const [getData, { loading, error, data }] = useLazyQuery(ENCODED_BY_DATE, {
    variables: { date },
  });

  useEffect(() => {
    if (!isInit) {
      getData({ variables: { date: DATE_TODAY } });
      setIsInit(true);
    }
  }, [getData, isInit]);

  function handleChange(dateValue: string) {
    setDate(dateValue);
    getData({ variables: { date: dateValue } });
  }

  return (
    <Card title={`Municipalities Encoded by Date`} loading={loading}>
      <DateWrapper>
        <input
          type="date"
          value={date}
          onChange={(event) => handleChange(event.target.value)}
        />
      </DateWrapper>

      {error || typeof data === "undefined" ? (
        <Text style={{ fontSize: "0.7rem" }}>Error Occured</Text>
      ) : (
        <List
          size="small"
          dataSource={data.encodedByDate.encoderDetails}
          renderItem={(item: any) => (
            <List.Item>
              {item.province}, {item.municipality}
            </List.Item>
          )}
          footer={
            <Text style={{ fontSize: "0.7rem" }}>Showing Data: {date}</Text>
          }
        ></List>
      )}
    </Card>
  );
}

const DateWrapper = styled("section")({
  textAlign: "right",
  marginBottom: "0.5rem",
});

const ENCODED_BY_DATE = gql`
  query GetEncodedByDate($date: String) {
    encodedByDate(date: $date) {
      latestUpdate
      encoderDetails {
        province
        municipality
      }
    }
  }
`;
