import { Alert, Layout } from "antd";
import {
  Content as AntContent,
  Footer as AntFooter,
  Header,
} from "antd/lib/layout/layout";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { DuplicateCaseIds } from "./DuplicateCaseIds";
import { DuplicateCaseNames } from "./DuplicateCaseNames";
import { EncodedByDate } from "./EncodedByDate";
import { EncodedToday } from "./EncodedToday";
import { MissingCaseIds } from "./MissingCaseIds";
import { Navbar } from "./Shared/Navbar";

export function Dashboard() {
  return (
    <Layout>
      <Header style={{ padding: 0 }}>
        <Navbar />
      </Header>

      <Content>
        <TitleWrapper>
          <Title level={3}>Welcome Back!</Title>

          <Text>
            System Date &amp; Time: {dayjs().format("YYYY-MM-DD hh:mmA")}
          </Text>
        </TitleWrapper>

        <Alert
          type="warning"
          message="Note: The data being shown is coming from the live CovidDETECT database."
          showIcon
          style={{ marginBottom: "1rem" }}
        />

        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter="16px">
            <EncodedToday />
            <MissingCaseIds />
            <DuplicateCaseNames />
            <EncodedByDate />
            <DuplicateCaseIds />
          </Masonry>
        </ResponsiveMasonry>
      </Content>
      <Footer>Developed by DOST 1 - ITSM Unit</Footer>
    </Layout>
  );
}

const Content = styled(AntContent)({
  padding: 24,
  paddingTop: 36,
  minHeight: "768px",
  width: "1200px",
  margin: "0 auto",

  "@media only screen and (max-width: 768px)": {
    width: "100%",
  },
});

const TitleWrapper = styled("section")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "1rem",
});

const Footer = styled(AntFooter)({
  color: "#dd3444",
  backgroundColor: "#FFF",
  textAlign: "center",
  fontSize: "12px",
  textTransform: "uppercase",
});
