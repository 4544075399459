import { gql, useQuery } from "@apollo/client";
import { Card, List } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";

export function MissingCaseIds() {
  const { loading, error, data } = useQuery(MISSING_CASE_IDS);

  return (
    <Card title={`Missing Case IDs`} loading={loading}>
      {error || typeof data === "undefined" ? (
        <Text style={{ fontSize: "0.7rem" }}>Error Occured</Text>
      ) : (
        <List
          size="small"
          dataSource={data.missingCaseIds.caseDetails}
          renderItem={(item: any) => <List.Item>{item.caseId}</List.Item>}
          footer={
            <Text style={{ fontSize: "0.7rem" }}>
              Total Missing:{" "}
              {typeof data !== "undefined" && data.missingCaseIds.count}
            </Text>
          }
        ></List>
      )}
    </Card>
  );
}

const MISSING_CASE_IDS = gql`
  query GetMissingCaseIds {
    missingCaseIds {
      count
      caseDetails {
        caseId
      }
    }
  }
`;
