import React from "react";
import styled from "styled-components";
import Logo from "../../assets/logo.png";

export function Navbar() {
  return (
    <Wrapper>
      <Brand>
        <img src={Logo} alt="CovidDETECT Logo" />
      </Brand>
    </Wrapper>
  );
}

const Wrapper = styled("nav")({
  backgroundColor: "#FFF",
  borderBottom: "1px Solid #EEE",
  padding: "0.25rem 1rem",

  "& h1": {
    marginBottom: 0,
  },
});

const Brand = styled("section")({
  width: "100%",
  textAlign: "center",

  "& img": {
    width: "40px",
  },
});
