import { useEffect, useState } from "react";
import { AUTH_LOCAL_STORAGE_KEY } from "../constants";

const AUTH_KEY =
  '[.W"nwT"~h#F#we"65}dCSC>(`Y8^J&}H-ZbxSKkg?h+nSV5V:ZeUtG!C5-*7;`vB8ynWA:6,Sz=<kBkw(*YAEWaz}.N8X;gJ&Hc_JD?TV3+YW?%"H=v_P*3QtY6(A^ax';
const TEMP_PASSWORD = "Hrpv6M^reMx_~dJU";

export function useAuth() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState<boolean>(false);

  useEffect(() => {
    const isAuthKey = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    if (isAuthKey !== null) return setIsAuth(true);
  }, []);

  function authenticate(password: string) {
    setIsLoading(true);
    setIsError(false);

    setTimeout(() => {
      setIsLoading(false);

      if (password !== TEMP_PASSWORD) {
        return setIsError(true);
      }

      const isAuthKey = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);

      if (isAuthKey === null) {
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, AUTH_KEY);
        setIsAuth(true);
      }
    }, 500);
  }

  return {
    isAuth,
    isLoading,
    isError,
    authenticate,
  };
}
