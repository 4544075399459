import { gql, useQuery } from "@apollo/client";
import { Card, List } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";

export function EncodedToday() {
  const { loading, error, data } = useQuery(ENCODED_TODAY);

  return (
    <Card title={`Municipalities Encoded Today`} loading={loading}>
      {error || typeof data === "undefined" ? (
        <Text style={{ fontSize: "0.7rem" }}>Error Occured</Text>
      ) : (
        <List
          size="small"
          dataSource={data.encodedToday.encoderDetails}
          renderItem={(item: any) => (
            <List.Item>
              {item.province}, {item.municipality}
            </List.Item>
          )}
          footer={
            <Text style={{ fontSize: "0.7rem" }}>
              Last Updated:{" "}
              {typeof data !== "undefined" && data.encodedToday.latestUpdate}
            </Text>
          }
        ></List>
      )}
    </Card>
  );
}

const ENCODED_TODAY = gql`
  query GetEncodedToday {
    encodedToday {
      latestUpdate
      encoderDetails {
        province
        municipality
      }
    }
  }
`;
