import { gql, useQuery } from "@apollo/client";
import { Card, List } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";

export function DuplicateCaseNames() {
  const { loading, error, data } = useQuery(DUPLICATE_CASE_IDS);

  return (
    <Card title={`Duplicate Names`} loading={loading}>
      {error || typeof data === "undefined" ? (
        <Text style={{ fontSize: "0.7rem" }}>Error Occured</Text>
      ) : (
        <List
          size="small"
          dataSource={data.duplicateCaseNames.caseDetails}
          renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
          footer={
            <Text style={{ fontSize: "0.7rem" }}>
              Total Duplicate Names:{" "}
              {typeof data !== "undefined" && data.duplicateCaseNames.count}
            </Text>
          }
        ></List>
      )}
    </Card>
  );
}

const DUPLICATE_CASE_IDS = gql`
  query GetDuplicateCaseNames {
    duplicateCaseNames {
      count
      caseDetails {
        name
      }
    }
  }
`;
