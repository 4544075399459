import "../config/theme.less";

import React from "react";
import { ApolloProvider } from "@apollo/client";
import { client } from "../services/GraphqlService";
import { Dashboard } from "./Dashboard";
import { useAuth } from "../hooks/useAuth";
import { SignIn } from "./SignIn";

export default function App() {
  const { isAuth, isLoading, isError, authenticate } = useAuth();

  return (
    <ApolloProvider client={client}>
      {isAuth ? (
        <Dashboard />
      ) : (
        <SignIn loading={isLoading} error={isError} onAuth={authenticate} />
      )}
    </ApolloProvider>
  );
}
