import { Alert, Button, Input, Spin } from "antd";
import styled from "styled-components";
import React from "react";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { LoadingOutlined } from "@ant-design/icons";
import Logo from "../assets/logo.png";

interface Props {
  loading: boolean;
  error: boolean;
  onAuth(password: string): void;
}

export function SignIn({ error, loading, onAuth }: Props) {
  function handleSubmit(values: any) {
    const password = values.password;

    if (typeof values !== "undefined") {
      onAuth(password);
    }
  }

  return (
    <Wrapper>
      <FormWrapper>
        <FormHeader>
          <img src={Logo} alt="CovidDETECT Logo" />
          <h1>COVIDDETECT STATUS</h1>
        </FormHeader>

        <section style={{ height: "300px" }}>
          {loading ? (
            <FormLoader>
              <Spin indicator={<LoadingOutlined spin />} />
            </FormLoader>
          ) : (
            <>
              {error && (
                <Alert
                  style={{ marginBottom: "0.5rem" }}
                  showIcon
                  type="error"
                  message="Invalid Access Code"
                />
              )}
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                requiredMark="optional"
              >
                <FormItem
                  label="Access Code"
                  rules={[
                    { required: true, message: "Access Code is required" },
                  ]}
                  name="password"
                >
                  <Input type="password" placeholder="Password" />
                </FormItem>

                <FormAction>
                  <Button type="primary" htmlType="submit" block>
                    Unlock
                  </Button>
                </FormAction>
              </Form>
            </>
          )}
        </section>
      </FormWrapper>
    </Wrapper>
  );
}

const Wrapper = styled("section")({
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const FormWrapper = styled("section")({
  width: "300px",
  padding: "1rem",
});

const FormHeader = styled("section")({
  marginBottom: "1rem",
  textAlign: "center",

  "& img": {
    width: " 100px",
  },
});

const FormAction = styled("section")({
  marginTop: "0.25rem",
});

const FormLoader = styled("section")({
  textAlign: "center",
  marginTop: "1rem",
});
