import { gql, useQuery } from "@apollo/client";
import { Card, List } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";

export function DuplicateCaseIds() {
  const { loading, error, data } = useQuery(DUPLICATE_CASE_IDS);

  return (
    <Card title={`Duplicate Case IDs`} loading={loading}>
      {error || typeof data === "undefined" ? (
        <Text style={{ fontSize: "0.7rem" }}>Error Occured</Text>
      ) : (
        <List
          size="small"
          dataSource={data.duplicateCaseIds.caseDetails}
          renderItem={(item: any) => <List.Item>{item.caseId}</List.Item>}
          footer={
            <Text style={{ fontSize: "0.7rem" }}>
              Total Duplicate IDs:{" "}
              {typeof data !== "undefined" && data.duplicateCaseIds.count}
            </Text>
          }
        ></List>
      )}
    </Card>
  );
}

const DUPLICATE_CASE_IDS = gql`
  query GetDuplicateCaseIds {
    duplicateCaseIds {
      count
      caseDetails {
        caseId
      }
    }
  }
`;
